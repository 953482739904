<template>
  <div>
    <slot
      name="activator"
      v-bind="{show}"
    />
    <b-modal
      v-model="display"
      title="Пополнение баланса"
      hide-footer
      centered
      no-body
      body-class="p-0"
    >
      <template #default="{ hide }">
        <b-form @submit.prevent="createOrder">

          <div class="p-2">
            <b-alert
              v-if="noMoneyAlert"
              show
              variant="danger"
            >
              <div class="alert-body">
                <p class="mb-0 font-medium-1 font-weight-bolder">
                  Недостаточно средств
                </p>
                Пополните баланс, чтобы продолжить
              </div>
            </b-alert>

            <b-form-group
              label-for="amount"
              label="Сумма пополнения"
            >
              <b-input-group prepend="RUB">
                <b-form-input
                  id="amount"
                  v-model="amount"
                  v-validate="'required|min_value:1'"
                  placeholder="Введите сумму"
                  name="amount"
                  data-vv-as="Сумма пополнения"
                  :state="errors.first('amount') ? false : null"
                  autofocus
                  type="number"
                  minvalue="0"
                />
              </b-input-group>
              <small class="text-danger">{{ errors.first('amount') }}</small>
            </b-form-group>
            <div style="display: grid; grid-template-columns: repeat(5, 1fr); grid-gap: 0.5rem">
              <b-button
                v-for="(am, key) in [500, 1000, 2000, 3000, 5000]"
                :key="`amount-${key}`"
                :variant="amount === am ? 'primary' : 'outline-primary'"
                @click="amount = am"
              >
                {{ am }}
              </b-button>
            </div>
          </div>
          <div class="px-2 pb-1">
            <span class="text-body">После нажатия "Оплатить", Вы будете перенаправлены на страницу оплаты.</span>
          </div>
          <div class="thin-divider" />
          <div class="p-2 d-flex align-items-center justify-content-between">
            <b-button
              variant="primary"
              type="submit"
            >
              Оплатить
            </b-button>
            <b-button
              variant="flat-secondary"
              type="button"
              @click="hide"
            >
              {{ $t('components.button.labels.cancel') }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BButton, BForm, BFormGroup, BFormInput, BInputGroup, BAlert,
} from 'bootstrap-vue'
import { toast } from '../../../helpers'

export default {
  name: 'CreatePaymentModal',
  components: {
    BModal, BButton, BForm, BFormGroup, BFormInput, BInputGroup, BAlert,
  },
  props: {
    noMoneyAlert: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    display: false,
    amount: null,
    type: 'balance',
  }),
  computed: {
    returnUrl() {
      return window.location.href
    },
  },
  methods: {
    show() {
      this.amount = null
      this.display = true
    },
    close() {
      this.display = false
    },
    async createOrder() {
      await this.$validator.validate().then(async valid => {
        if (valid) {
          const { amount, returnUrl } = this
          const response = await this.$http.post('/balance/replenish', {
            amount,
            return_url: returnUrl,
            pay_subscription: false,
          }).catch(error => {
            toast({ title: 'Ошибка', text: error?.data?.data?.message || 'Неизвестная ошибка', type: 'danger' })
          })
          if (response?.url) window.location.href = response.url
        }
        return null
      })
    },
  },
}
</script>

<style scoped>

</style>
