<template>
  <b-row>
    <b-col>
      <b-card
        no-body
        class="mb-0"
      >
        <table-header
          search
          date
          :filter="filter"
          :search-updated="getPayments"
        />
        <b-table
          ref="teachersTable"
          striped
          :items="payments"
          responsive
          :fields="tableFields"
          primary-key="id"
          show-empty
          :empty-text="$t('page.students.search.labels.no-items')"
          :sort-by.sync="filter.sortBy"
          :sort-desc.sync="filter.sortDesc"
        >
          <template #cell(purpose)="{item}">
            <div>
              {{ purposes[item.purpose] }}
            </div>
          </template>
          <template #cell(amount)="{item}">
            <div class="d-flex flex-row justify-content-start align-items-center">
              <b-badge :variant="item.type === 'credit' ? 'success' : 'danger'">
                {{ item.type === 'credit' ? '+' : '-' }} {{ item.amount }}
              </b-badge>
            </div>
          </template>
          <template #cell(status)="{item}">
            <div class="d-flex flex-row justify-content-start align-items-center">
              <b-badge :variant="status(item.status).color">
                {{ status(item.status).title }}
              </b-badge>
            </div>
          </template>
          <template #cell(actions)="{item}">
            <div class="d-flex flex-row justify-content-start align-items-center">
              <b-button
                v-if="item.order && ['new', 'in_progress'].includes(item.status)"
                :href="item.order.payment_url"
                variant="primary"
                size="sm"
              >
                Оплатить
              </b-button>
            </div>
          </template>
          <template #cell(balance_amount)="{item}">
            <div>
              {{ parseFloat(item.balance_amount) + parseFloat(item.amount) | price }}
            </div>
          </template>
        </b-table>
        <table-footer
          :pagination-updated="getPayments"
          :pagination="pagination"
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BButton,
  BRow, BCol, BTable, BBadge,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import TableHeader from '../page-elements/table/TableHeader.vue'
import TableFooter from '../page-elements/table/TableFooter.vue'

export default {
  name: 'UserPayments',
  components: {
    TableFooter,
    TableHeader,
    BRow,
    BCol,
    BTable,
    BBadge,
    BCard,
    BButton,
  },
  data: () => ({
    payments: [],
    pagination: {
      perPage: 15,
      perPageOptions: [5, 15, 30],
      totalCount: null,
      currentPage: 1,
    },
    widgets: {
      total_sum: 0,
      total_subscriptions: 0,
    },
    filter: {
      date_range: '',
      search: '',
      sortBy: 'created',
      sortDesc: true,
    },
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    sortBy() {
      if (!this.filter.sortBy) return null
      let { sortBy } = this.filter
      if (this.filter.sortDesc) sortBy = `-${sortBy}`
      else sortBy = `+${sortBy}`
      return sortBy
    },
    tableFields() {
      return [
        {
          key: 'id',
          label: 'Номер',
          sortable: true,
        },
        {
          key: 'purpose',
          label: 'Назначение',
          sortable: true,
          formatter: value => this.type(value),
        },
        {
          key: 'amount',
          label: this.$t('page.admin_subscriptions.table.labels.price'),
          sortable: true,
          formatter: value => this.$options.filters.price(value),
        },
        {
          key: 'created',
          label: 'Дата',
          sortable: true,
          formatter: (value, key, row) => (row.created ? this.$options.filters.dateTime(row.created) : this.$t('no_data')),
        },
        {
          key: 'balance_amount',
          label: 'Баланс',
          sortable: true,
        },
        {
          key: 'status',
          label: this.$t('page.admin_subscriptions.table.labels.status'),
          sortable: true,
        },
        {
          key: 'actions',
          label: 'Действия',
          sortable: false,
        },
      ]
    },
    types() {
      return {
        balance: 'Пополнение кошелька',
        subscription: 'Оплата подписки',
      }
    },
    purposes() {
      return {
        buy_balance: 'Пополнение кошелька',
        pay_subscription: 'Оплата подписки',
        commission_fee: 'Комиссия за урок',
      }
    },
    statuses() {
      return {
        new: {
          title: 'Новый',
          color: 'warning',
        },
        in_progress: {
          title: 'Обрабатывается',
          color: 'info',
        },
        completed: {
          title: 'Выполнен',
          color: 'success',
        },
        canceled: {
          title: 'Отменён',
          color: 'danger',
        },
        error: {
          title: 'Ошибка',
          color: 'danger',
        },
      }
    },
  },
  watch: {
    sortBy() {
      this.getPayments()
    },
  },
  async created() {
    await this.getPayments()
  },
  methods: {
    async getPayments() {
      const { items, _meta } = await this.$http.get('/balance-transaction/current-user-index', {
        params: {
          sort: this.sortBy,
          page: this.pagination.currentPage,
          limit: this.pagination.perPage,
          created: this.filter.date_range,
          user_id: this.user.id,
          expand: 'order',
        },
      })
      this.pagination.totalCount = _meta.totalCount
      this.payments = items
    },
    status(status) {
      if (!this.statuses[status]) return { title: status }
      return this.statuses[status]
    },
    type(type) {
      if (!this.types[type]) return type
      return this.types[type]
    },
  },
}
</script>

<style scoped>

</style>
