<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col
          cols="4"
          lg="2"
          class="d-flex justify-content-center"
        >
          <b-avatar
            size="120px"
            class="mb-2"
            :src="imageUrl"
          />
        </b-col>
        <b-col
          cols="8"
          lg="10"
          class="d-flex flex-column "
        >
          <b-row>
            <b-col cols="12">
              <span class="modal-title h1">
                {{ $t('page.profile.labels.modals.edit-profile.title') }}
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <template v-if="canEdit">
                <b-row class="mb-1">
                  <b-col
                    cols="4"
                    lg="3"
                  >
                    <b-button
                      variant="primary"
                      type="button"
                      block
                      :disabled="!canEdit"
                      @click="() => $refs.imageInput.click()"
                    >
                      {{ $t('components.button.labels.upload') }}
                    </b-button>
                    <input
                      ref="imageInput"
                      type="file"
                      name="image"
                      style="display: none;"
                      accept=".png, .jpeg, .jpg"
                      @change="e => loadAvatar(e.target.files[0])"
                    >
                  </b-col>
                  <b-col
                    cols="4"
                    lg="3"
                  >
                    <b-button
                      variant="outline-secondary"
                      type="button"
                      block
                      :disabled="!canEdit"
                      @click="resetImage"
                    >
                      {{ $t('components.button.labels.reset') }}
                    </b-button>
                  </b-col>
                  <b-col />
                  <b-col
                    v-if="isCurrentAdmin"
                    cols="auto"
                  >
                    <b-button
                      :variant="form.status === 'verified' ? 'success' : 'warning'"
                      type="button"
                      @click="toggleVerification"
                    >
                      {{ form.status === 'verified' ? 'Верифицирован' : 'Верифицировать' }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row class="pb-1">
                  <b-col cols="12">
                    {{ $t('page.profile.labels.modals.edit-profile.allowed-file-types') }}: .png, .jpeg, .jpg
                  </b-col>
                </b-row>
              </template>
              <template v-else>
                <h3 class="text-success mt-1">
                  Вы верифицированы.
                </h3>
                <p>Чтобы изменить данные профиля, обратитесь к администратору</p>
              </template>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12">
      <b-row>
        <b-col cols="12">
          <validation-observer
            ref="editUserForm"
            v-slot="{invalid}"
          >
            <b-form @submit.prevent="() => updateProfile()">
              <b-row>
                <b-col
                  md="4"
                  sm="12"
                >
                  <b-form-group
                    label-for="name"
                    :label="$t('page.profile.labels.modals.edit-profile.name')"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="name"
                      rules.disable="required"
                    >
                      <b-input-group
                        :class="errors.length > 0 ? 'is-invalid':null"
                        label-for="name"
                        :label="$t('page.profile.labels.modals.edit-profile.name')"
                      >
                        <b-form-input
                          id="name"
                          v-model="form.name"
                          :disabled="!canEdit"
                          type="text"
                          :placeholder="$t('page.profile.labels.modals.edit-profile.name')"
                          :state="errors.length > 0 ? false:null"
                          name="name"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="4"
                  sm="12"
                >
                  <b-form-group
                    label-for="patronymic"
                    :label="$t('page.profile.labels.modals.edit-profile.patronymic')"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="patronymic"
                      rules.disable="required"
                    >
                      <b-input-group
                        :class="errors.length > 0 ? 'is-invalid':null"
                        label-for="patronymic"
                        :label="$t('page.profile.labels.modals.edit-profile.patronymic')"
                      >
                        <b-form-input
                          id="patronymic"
                          v-model="form.patronymic"
                          type="text"
                          :disabled="!canEdit"
                          :placeholder="$t('page.profile.labels.modals.edit-profile.patronymic')"
                          :state="errors.length > 0 ? false:null"
                          name="patronymic"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="4"
                  sm="12"
                >
                  <b-form-group
                    label-for="surname"
                    :label="$t('page.profile.labels.modals.edit-profile.surname')"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="surname"
                      rules.disable="required"
                    >
                      <b-input-group
                        :class="errors.length > 0 ? 'is-invalid':null"
                        label-for="surname"
                        :label="$t('page.profile.labels.modals.edit-profile.surname')"
                      >
                        <b-form-input
                          id="surname"
                          v-model="form.surname"
                          type="text"
                          :disabled="!canEdit"
                          :placeholder="$t('page.profile.labels.modals.edit-profile.surname')"
                          :state="errors.length > 0 ? false:null"
                          name="surname"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="isTeacher">
                <b-col>
                  <b-form-group
                    label-for="description"
                    label="О себе"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="description"
                      rules.disable="required"
                    >
                      <b-input-group
                        :class="errors.length > 0 ? 'is-invalid':null"
                        label-for="surname"
                        label="О себе"
                      >
                        <b-form-textarea
                          id="description"
                          v-model="form.description"
                          rows="3"
                          type="text"
                          placeholder="О себе"
                          :state="errors.length > 0 ? false:null"
                          name="description"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col v-if="isCurrentAdmin">
                  <b-form-group
                    label-for="description"
                    label="Комментарий администратора"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="admin_comment"
                      rules.disable="required"
                    >
                      <b-input-group
                        :class="errors.length > 0 ? 'is-invalid':null"
                        label-for="surname"
                        label="Комментарий администратора"
                      >
                        <b-form-textarea
                          id="admin_comment"
                          v-model="form.admin_comment"
                          rows="3"
                          type="text"
                          placeholder="Введите текст комментария"
                          :state="errors.length > 0 ? false:null"
                          name="description"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    label-for="age"
                    label="Возраст"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="age"
                      rules.disable="required"
                    >
                      <b-input-group
                        :class="errors.length > 0 ? 'is-invalid':null"
                        label-for="age"
                        label="Возраст"
                      >
                        <b-form-input
                          id="age"
                          v-model="form.age"
                          type="text"
                          placeholder="Возраст"
                          :state="errors.length > 0 ? false:null"
                          name="age"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label-for="email"
                    :label="$t('page.profile.labels.modals.edit-profile.email')"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="email"
                      rules.disable="required"
                    >
                      <b-input-group
                        :class="errors.length > 0 ? 'is-invalid':null"
                        label-for="email"
                        :label="$t('page.profile.labels.modals.edit-profile.email')"
                      >
                        <b-form-input
                          id="email"
                          v-model="form.email"
                          type="email"
                          :placeholder="$t('page.profile.labels.modals.edit-profile.email')"
                          :state="errors.length > 0 ? false:null"
                          name="email"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    label-for="phone"
                    :label="$t('page.profile.labels.modals.edit-profile.phone')"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="phone"
                      rules.disable="required"
                    >
                      <b-input-group
                        :class="errors.length > 0 ? 'is-invalid':null"
                        label-for="phone"
                        :label="$t('page.profile.labels.modals.edit-profile.phone')"
                      >
                        <cleave
                          id="phone"
                          v-model="form.phone"
                          name="phone"
                          class="form-control"
                          :raw="false"
                          :options="options.phone"
                          placeholder="8 800 800 80 80"
                          :state="errors.length > 0 ? false:null"
                          disabled
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label-for="phone_additional"
                    :label="$t('page.profile.labels.modals.edit-profile.phone_additional')"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="phone_additional"
                      rules.disable="required"
                    >
                      <b-input-group
                        :class="errors.length > 0 ? 'is-invalid':null"
                        label-for="phone_additional"
                        :label="$t('page.profile.labels.modals.edit-profile.phone_additional')"
                      >
                        <cleave
                          id="phone_additional"
                          v-model="form.phone_additional"
                          name="phone_additional"
                          class="form-control"
                          :raw="false"
                          :options="options.phone"
                          placeholder="8 800 800 80 80"
                          :state="errors.length > 0 ? false:null"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    label-for="skype"
                    :label="$t('page.profile.labels.modals.edit-profile.skype')"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="skype"
                      rules.disable="required"
                    >
                      <b-input-group
                        :class="errors.length > 0 ? 'is-invalid':null"
                        label-for="skype"
                        :label="$t('page.profile.labels.modals.edit-profile.skype')"
                      >
                        <b-form-input
                          id="skype"
                          v-model="form.skype"
                          type="text"
                          :placeholder="$t('page.profile.labels.modals.edit-profile.skype')"
                          :state="errors.length > 0 ? false:null"
                          name="skype"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label-for="floor"
                    :label="$t('page.profile.labels.modals.edit-profile.floor')"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="floor"
                      rules.disable="required"
                    >
                      <b-input-group
                        :class="errors.length > 0 ? 'is-invalid':null"
                        label-for="floor"
                        :label="$t('page.profile.labels.modals.edit-profile.floor')"
                      >
                        <v-select
                          v-model="form.floor"
                          :options="floorOptions"
                          class="w-100"
                          label="title"
                          :reduce="option => option.value"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <template v-if="isStudent">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label-for="group"
                      label="Класс"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="group"
                        rules.disable="required"
                      >
                        <b-input-group
                          :class="errors.length > 0 ? 'is-invalid':null"
                          label-for="group"
                          label="Класс"
                        >
                          <b-form-input
                            id="group"
                            v-model="form.group"
                            type="text"
                            placeholder="Класс"
                            :state="errors.length > 0 ? false:null"
                            name="group"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="4"
                    sm="12"
                  >
                    <b-form-group
                      label-for="parent_name"
                      :label="$t('page.profile.labels.modals.edit-profile.parent_name')"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="parent_name"
                        rules.disable="required"
                      >
                        <b-input-group
                          :class="errors.length > 0 ? 'is-invalid':null"
                          label-for="parent_name"
                          :label="$t('page.profile.labels.modals.edit-profile.parent_name')"
                        >
                          <b-form-input
                            id="parent_name"
                            v-model="form.parent_name"
                            type="text"
                            :placeholder="$t('page.profile.labels.modals.edit-profile.parent_name')"
                            :state="errors.length > 0 ? false:null"
                            name="parent_name"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="4"
                    sm="12"
                  >
                    <b-form-group
                      label-for="parent_surname"
                      :label="$t('page.profile.labels.modals.edit-profile.parent_surname')"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="parent_surname"
                        rules.disable="required"
                      >
                        <b-input-group
                          :class="errors.length > 0 ? 'is-invalid':null"
                          label-for="parent_surname"
                          :label="$t('page.profile.labels.modals.edit-profile.parent_surname')"
                        >
                          <b-form-input
                            id="parent_surname"
                            v-model="form.parent_surname"
                            type="text"
                            :placeholder="$t('page.profile.labels.modals.edit-profile.parent_surname')"
                            :state="errors.length > 0 ? false:null"
                            name="parent_surname"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="4"
                    sm="12"
                  >
                    <b-form-group
                      label-for="parent_patronymic"
                      :label="$t('page.profile.labels.modals.edit-profile.parent_patronymic')"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="parent_patronymic"
                        rules.disable="required"
                      >
                        <b-input-group
                          :class="errors.length > 0 ? 'is-invalid':null"
                          label-for="parent_patronymic"
                          :label="$t('page.profile.labels.modals.edit-profile.parent_patronymic')"
                        >
                          <b-form-input
                            id="parent_patronymic"
                            v-model="form.parent_patronymic"
                            type="text"
                            :placeholder="$t('page.profile.labels.modals.edit-profile.parent_patronymic')"
                            :state="errors.length > 0 ? false:null"
                            name="parent_patronymic"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
              <b-row v-if="isTeacher">
                <b-col>
                  <b-form-group
                    label-for="diplomas"
                    label="Образование"
                  >
                    <b-input-group
                      v-for="(diploma, key) in form.diplomas"
                      :key="`diploma-${key}`"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      label-for="diploma"
                      label="Образование"
                      class="mb-50"
                    >
                      <b-form-input
                        id="diploma"
                        v-model="form.diplomas[key]"
                        type="text"
                        :disabled="!canEdit"
                        placeholder="Введите наименование документа"
                        :state="errors.length > 0 ? false:null"
                        name="diploma"
                      />
                      <b-input-group-append>
                        <b-button
                          :disabled="!canEdit"
                          type="button"
                          variant="danger"
                          size="sm"
                          @click="form.diplomas.splice(key, 1)"
                        >
                          <feather-icon
                            icon="TrashIcon"
                            size="15"
                          />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <p
                      v-if="!form.diplomas || !form.diplomas.length"
                      class="mb-0"
                    >
                      Добавьте Ваши дипломы и иные документы
                    </p>
                    <b-button
                      v-if="canEdit"
                      type="button"
                      size="sm"
                      variant="flat-primary"
                      @click="() => form.diplomas.push('')"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                      />
                      Добавить документ
                    </b-button>
                  </b-form-group>
                </b-col>
                <b-col>
                  <notifications :disabled="!canEdit" />
                </b-col>
              </b-row>
              <div class="d-flex">
                <b-button
                  class="mr-1"
                  variant="primary"
                  type="submit"
                  :disabled="invalid"
                >
                  {{ $t('components.button.labels.save') }}
                </b-button>
                <b-button
                  variant="flat-secondary"
                  type="button"
                  @click="$emit('close')"
                >
                  {{ $t('components.button.labels.cancel') }}
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BForm, BFormGroup, BInputGroup, BFormInput, BButton, BAvatar, BFormTextarea, BInputGroupAppend,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import Cleave from 'vue-cleave-component'
import {
  adminRole, studentRole, teacherRole, userRoles,
} from '../../store/user'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.ru'
// eslint-disable-next-line import/order
import vSelect from 'vue-select'
import Notifications from './tabs/Notifications.vue'

export default {
  name: 'ProfileEditor',
  components: {
    Notifications,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BAvatar,
    Cleave,
    vSelect,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {},
    image: null,
  }),
  computed: {
    ...mapGetters({
      currentUser: 'user/get_user',
    }),
    options() {
      return {
        phone: {
          blocks: [1, 3, 3, 2, 2],
          delimiters: ['(', ')', '-', '-'],
        },
      }
    },
    floorOptions() {
      return [
        {
          title: this.$t('page.profile.labels.modals.edit-profile.male'),
          value: 1,
        },
        {
          title: this.$t('page.profile.labels.modals.edit-profile.female'), value: 2,
        },
      ]
    },
    imageUrl() {
      return this.image?.full_url
    },
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    currentUserRole() {
      return this.currentUser ? userRoles[this.currentUser.role] : ''
    },
    additionalPath() {
      if (this.userRole === teacherRole) {
        return 'usersTeachers'
      }
      if (this.userRole === studentRole) {
        return 'usersStudent'
      }
      return null
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    isStudent() {
      return this.userRole === studentRole
    },
    isCurrentAdmin() {
      return this.currentUserRole === adminRole
    },
    isVerified() {
      return this.form.status === 'verified'
    },
    canEdit() {
      return !this.isVerified || this.isCurrentAdmin
    },
  },
  mounted() {
    this.image = this.user?.image
    const profile = { ...this.user, ...this.user[this.additionalPath] }
    delete profile[this.additionalPath]

    this.form = { ...profile, diplomas: profile.diplomas }
  },
  methods: {
    ...mapActions({
      updateUserProfile: 'user/updateUserProfile',
    }),
    async loadAvatar(file) {
      const image = await this.$http.storeCreate(file)
      this.image = image
      this.form.image_id = image?.id
    },
    updateProfile(stay = false) {
      let url = '/user/current-update-profile'
      if (this.isCurrentAdmin) url = `/user/update-profile?id=${this.user.id}`

      this.form.diplomas = [...(this.form.diplomas ?? []).filter(diploma => diploma.trim() !== '')]

      this.$http.put(url, this.form)
        .then(async profile => {
          this.form = { ...profile }
          if (profile.phone === this.currentUser.phone) await this.updateUserProfile(profile)
          if (!stay) this.$emit('close')
        })
        .catch(() => {

        })
    },
    resetImage() {
      this.image = null
      this.form.image_id = null
    },
    toggleVerification() {
      this.form.status = this.form.status === 'verified' ? 'unverified' : 'verified'
      this.updateProfile(true)
      // this.$http.put(`/users-teachers/update?id=${this.user[this.additionalPath].id}`, {
      //   status: this.form.status,
      // })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
