<template>
  <b-card v-if="user && profile">
    <div class="d-flex flex-column justify-content-between align-items-center">
      <b-avatar
        size="100px"
        class="mb-2"
        :src="imageUrl"
      />
    </div>
    <b-row>
      <b-col cols="12">
        <h4 class="card-title text-center mb-1">
          {{ fullName || user.username || user.email }}
        </h4>
      </b-col>
      <b-col
        cols="12"
        class="text-center"
      >
        <span>
          <b-badge>{{ userRole }}</b-badge>
        </span>
      </b-col>
      <b-col cols="12">
        <ul class="profile-info-list list-group mt-2">
          <li class="list-inline-item">
            <b>{{ $t('page.profile.labels.profile-info.username') }}:</b>
            <span :class="{'font-italic': !username}">{{ username || $t('labels.not-provided') }}</span>
          </li>
          <li class="list-inline-item"><b>{{ $t('page.profile.labels.profile-info.email') }}:</b>
            <span :class="{'font-italic': !profile.email}">{{ profile.email || $t('labels.not-provided') }}</span>
          </li>
          <li class="list-inline-item">
            <b>{{ $t('page.profile.labels.profile-info.phone') }}:</b>
            <span :class="{'font-italic': !profile.phone}">{{ profile.phone || $t('labels.not-provided') }}</span>
          </li>
          <li class="list-inline-item">
            <b>{{ $t('page.profile.labels.profile-info.phone_additional') }}:</b>
            <span :class="{'font-italic': !profile.phone_additional}">{{ profile.phone_additional || $t('labels.not-provided') }}</span>
          </li>
          <li class="list-inline-item"><b>{{ $t('page.profile.labels.profile-info.skype') }}:</b>
            <span :class="{'font-italic': !profile.skype}">{{ profile.skype || $t('labels.not-provided') }}</span>
          </li>
        </ul>
      </b-col>
      <b-col
        cols="12"
        class="text-center"
      >
        <b-button
          variant="warning"
          type="submit"
          class="mt-2"
          @click="$emit('edit')"
        >
          {{ $t('components.button.labels.edit') }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol,
  BCard, BButton, BAvatar, BBadge,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { studentRole, teacherRole, userRoles } from '@/store/user'
import { getUserFullName } from '../../helpers'

export default {
  name: 'UserProfileView',
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BBadge,
  },
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      profile: 'user/get_profile',
    }),
    imageUrl() {
      return this.profile.image ? `${process.env.VUE_APP_API_URL}/${this.profile.image}` : null
    },
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    additionalPath() {
      if (this.userRole === teacherRole) {
        return 'usersTeachers'
      }
      if (this.userRole === studentRole) {
        return 'usersStudent'
      }
      return null
    },
    fullName() {
      return getUserFullName(this.user)
    },
    username() {
      return this.profile.username || this.profile.username
    },
  },
}
</script>
