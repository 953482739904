<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-card-title>
          {{ $t('page.profile.labels.tabs.security.change-password') }}
        </b-card-title>
        <validation-observer
          ref="changePasswordForm"
          #default="{invalid}"
        >
          <b-form @submit.prevent="changePassword">
            <b-row>
              <b-col
                md="6"
                sm="12"
              >
                <b-form-group
                  label-for="password"
                  :label="$t('page.profile.labels.tabs.security.new-password')"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="password"
                    rules.disable="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      label-for="password"
                      :label="$t('page.profile.labels.tabs.security.new-password')"
                    >
                      <b-form-input
                        id="password"
                        v-model="password"
                        :type="hide_first_password ? 'password' : 'text'"
                        :placeholder="$t('page.profile.labels.tabs.security.password')"
                        class="form-control-merge"
                        :state="errors.length > 0 ? false:null"
                        name="password"
                      />

                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="hide_first_password ? 'EyeIcon' : 'EyeOffIcon'"
                          @click="hide_first_password = !hide_first_password"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <b-form-group
                  label-for="password_repeat"
                  :label="$t('page.profile.labels.tabs.security.repeat-password')"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="password_repeat"
                    rules.disable="required|confirmed:password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      label-for="password_repeat"
                      :label="$t('page.profile.labels.tabs.security.repeat-password')"
                    >
                      <b-form-input
                        id="password_repeat"
                        v-model="password_repeat"
                        :type="hide_second_password ? 'password' : 'text'"
                        :placeholder="$t('page.profile.labels.tabs.security.password-confirm')"
                        class="form-control-merge"
                        :state="errors.length > 0 ? false:null"
                        name="password_repeat"
                      />

                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="hide_second_password ? 'EyeIcon' : 'EyeOffIcon'"
                          @click="hide_second_password = !hide_second_password"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                sm="12"
                md="4"
              >
                <b-button
                  variant="primary"
                  type="submit"
                  block
                  :disabled="invalid"
                >
                  {{ $t('components.button.labels.save') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BForm, BCardTitle, BFormGroup, BInputGroup, BFormInput, BButton, BInputGroupAppend,
} from 'bootstrap-vue'
import { required, confirmed } from 'vee-validate/dist/rules.esm'

export default {
  name: 'Security',
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BCardTitle,
    BFormGroup,
    BInputGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BInputGroupAppend,
  },
  data: () => ({
    hide_first_password: true,
    hide_second_password: true,
    password: null,
    password_repeat: null,
    required,
    confirmed,
  }),
  methods: {
    async changePassword() {
      this.$http.put('/user/current-update-password', { password: this.password, password_repeat: this.password_repeat })
        .then(() => {
          this.password = null
          this.password_repeat = null
        })
        .catch(errors => {
          this.$refs.changePasswordForm.setErrors(errors)
        })
    },
  },
}
</script>
