<template>
  <div>
    <b-row v-if="profile && editProfile">
      <b-col>
        <b-card>
          <profile-editor
            :user="user"
            @close="() => editProfile = false"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col
        cols="12"
        xl="4"
        lg="4"
        md="6"
      >
        <user-profile-view @edit="() => editProfile = true" />
      </b-col>
      <b-col
        cols="12"
        xl="8"
        lg="8"
        md="6"
      >
        <b-tabs>
          <!--          <b-tab-->
          <!--            lazy-->
          <!--          >-->
          <!--            <template #title>-->
          <!--              <feather-icon icon="BookmarkIcon" />-->
          <!--              <span>{{ $t('page.profile.labels.tabs.plans-and-billings.title') }}</span>-->
          <!--            </template>-->
          <!--            <subscriptions-->
          <!--              hide-image-on-tablet-->
          <!--            />-->
          <!--          </b-tab>-->
          <b-tab lazy>
            <template #title>
              <feather-icon icon="LockIcon" />
              <span>{{ $t('page.profile.labels.tabs.security.title') }}</span>
            </template>
            <security />
          </b-tab>
          <b-tab
            v-if="isTeacher"
            lazy
            disabled
          >
            <template #title>
              <feather-icon icon="BellIcon" />
              <span>{{ $t('page.profile.labels.tabs.notifications.title') }}</span>
            </template>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <b-row v-if="isStudent">
      <b-col>
        <div class="mt-2 mb-1 d-flex justify-content-between">
          <h2 class="mb-0">
            Счета и оплаты
          </h2>
          <create-payment-modal>
            <template #activator="{show}">
              <b-button
                variant="primary"
                @click="show"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                Пополнить
              </b-button>
            </template>
          </create-payment-modal>
        </div>
        <user-payments />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow, BCol, BTabs, BTab,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import UserProfileView from '@/components/profile/UserProfileView.vue'
import Security from '@/components/profile/tabs/Security.vue'
import ProfileEditor from '../components/profile/ProfileEditor.vue'
import { studentRole, teacherRole, userRoles } from '@/store/user'
import UserPayments from '@/components/payments/UserPayments.vue'
import CreatePaymentModal from '@/components/profile/modals/CreatePaymentModal.vue'

export default {
  components: {
    CreatePaymentModal,
    UserPayments,
    ProfileEditor,
    Security,
    UserProfileView,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
  },
  data: () => ({
    editProfile: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      profile: 'user/get_profile',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    isStudent() {
      return this.userRole === studentRole
    },
  },
  async created() {
    await this.getProfile()
  },
  methods: {
    ...mapActions({
      getProfile: 'user/getProfile',
    }),
  },
}
</script>
