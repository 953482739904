<template>
  <div>
    <b-card-title class="mb-1">
      Мои предметы
    </b-card-title>
    <b-card-sub-title class="font-weight-normal">
      Укажите предметы, по которым Вы занимаетесь и хотите получать заявки
    </b-card-sub-title>
    <div class="mt-1">
      <v-select
        v-model="subjectsIds"
        :disabled="disabled"
        :options="subjects"
        label="text"
        name="subject_id"
        :reduce="s => s.id"
        placeholder="Выберите предметы"
        multiple
        :loading="saving"
        @input="savePreferences"
      />
    </div>
  </div>
</template>

<script>
import {
  BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  name: 'Notifications',
  components: {
    BCardTitle, BCardSubTitle, vSelect,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    subjectsIds: [],
    gradesIds: [],
    saving: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
      subjects: 'course/get_subjects',
      grades: 'course/get_grades',
    }),
  },
  async mounted() {
    if (!this.subjects.length) this.getSubjects()
    if (!this.grades.length) this.getGrades()

    this.saving = true
    const subjects = await this.$chatsHttp.get('/user/teacher/subjects')
    this.subjectsIds = subjects.map(({ id }) => id)
    this.saving = false
  },
  methods: {
    ...mapActions({
      getSubjects: 'course/getSubjects',
      getGrades: 'course/getGrades',
    }),
    async savePreferences() {
      this.saving = true
      await this.$chatsHttp.put('/user/teacher/subjects', { subjects_id: this.subjectsIds })
      this.saving = false
    },
  },
}
</script>

<style scoped>

</style>
